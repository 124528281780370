import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-achievement-gauge',
    template: `<app-gauge
        [radius]="radius"
        [value]="value * 100"
        [appearance]="appearance"
        [labels]="labels"
        [zones]="zones"
        [maxValue]="maxValue"
    ></app-gauge>`,
})
export class AchievementGaugeComponent {
    @Input() public value: number = 0;
    @Input() public radius: number = 150;
    @Input() public appearance: 'dark' | 'light' = 'dark';
    @Input() public labels = [0, 20, 40, 60, 80, 100, 120, 140];
    @Input() public maxValue = 140;
    @Input() public zones = [
        { strokeStyle: '#F03E3E', min: 0, max: 60 },
        { strokeStyle: '#FFDD00', min: 60, max: 90 },
        { strokeStyle: '#30B32D', min: 90, max: 120 },
        {
            strokeStyle: '#00AAFF',
            min: 120,
            max: this.value > 1.4 ? this.value * 100 : 140,
        },
    ];
}
