import { Injectable } from '@angular/core';
import { Result } from 'mnm-webapp';
import { Observable, of, OperatorFunction, Subject } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
    Item,
    KpiType,
    Permission,
    PermissionGroup,
} from '@masar/common/models';
import { environment } from '@masar/env/environment';
import { TranslateService } from '@ngx-translate/core';
import { AppSettingFetcherService } from './app-setting-fetcher.service';
import { HttpQueryParameters } from '@masar/features/http-crud/http-query-parameters.interface';
import { getHttpParameters } from '@masar/features/http-crud/utils';
import {
    BenchmarkMiscItem,
    GovernmentStrategicGoalMiscItem,
    KpiMiscItem,
    MiscApiEndPoint,
    MiscItem,
    RiskImpactMiscItem,
    RiskProbabilityMiscItem,
    StrategicGoalMiscItem,
} from '@masar/common/types';
import { MnmFormState } from '@masar/shared/components';

class MiscApiCache {
    private cache = new Map<string, Observable<MiscItem[]>>();

    public constructor(private httpClient: HttpClient) {}

    private static generateKey(url: string, params: HttpParams): string {
        return `${url}.${params.toString()}`;
    }

    public get(
        url: string,
        params: HttpParams = new HttpParams()
    ): Observable<MiscItem[]> {
        // Generate a key using the url and params.
        // this will prevent providing the same
        // list when the params have changed.
        const key = MiscApiCache.generateKey(url, params);

        // If the list is available, then return
        // it as is.
        if (this.cache.has(key)) {
            return this.cache.get(key);
        }

        // Otherwise, fetch it first, return the request,
        // and store it in the cache.
        else {
            const data$ = new Subject<MiscItem[]>();
            const dataObs = data$.asObservable();

            this.httpClient
                .get<Result<MiscItem[]>>(url, { params })
                .subscribe(res => {
                    data$.next(res.extra);
                    data$.complete();
                    this.cache.set(key, of(res.extra));
                });

            this.cache.set(key, dataObs);

            return dataObs;
        }
    }
}

@Injectable()
export class MiscApiService {
    private cache: MiscApiCache;

    public constructor(
        private httpClient: HttpClient,
        private readonly translateService: TranslateService,
        private readonly appSettingFetcherService: AppSettingFetcherService
    ) {
        this.cache = new MiscApiCache(httpClient);
    }

    public permissions(): Observable<Permission[]> {
        return this.httpClient
            .get<Result<Permission[]>>(environment.apiUrl + '/misc/permission')
            .pipe(map(result => result.extra));
    }

    public permissionGroups(): Observable<PermissionGroup[]> {
        return this.httpClient
            .get<Result<PermissionGroup[]>>(
                environment.apiUrl + '/misc/permission-group'
            )
            .pipe(map(result => result.extra));
    }

    public kpis({
        keyword = '',
        operationIds = [],
        departmentIds = [],
        isLinkableToPlans = false,
        scope = 'all',
        pageSize = 20,
    } = {}): Observable<Item[]> {
        const url = environment.apiUrl + '/misc/kpi';

        let params = new HttpParams()
            .append('keyword', keyword)
            .append('isLinkableToPlans', `${isLinkableToPlans}`)
            .append('scope', scope)
            .append('pageSize', `${pageSize}`);

        operationIds.forEach(x => (params = params.append('operationIds', x)));

        departmentIds.forEach(
            x => (params = params.append('departmentIds', x))
        );

        return this.httpClient.get<Result<KpiMiscItem[]>>(url, { params }).pipe(
            map(result => result.extra),
            this.kpiMapper()
        );
    }

    public kpiResultCapabilityTypes({
        keyword = '',
        pageSize = 1000,
    } = {}): Observable<Item[]> {
        return this.httpClient
            .get<Result<Item[]>>(
                environment.apiUrl + '/misc/kpi-result-capability-type',
                {
                    params: new HttpParams()
                        .append('keyword', keyword)
                        .append('pageSize', `${pageSize}`),
                }
            )
            .pipe(map(result => result.extra));
    }

    public departments({
        parentDepartmentId = '',
        respectHierarchy = false,
        scope = 'all',
    }: {
        parentDepartmentId?: string;
        respectHierarchy?: boolean;
        scope?: 'all' | 'broad' | 'specific';
    } = {}): Observable<Item[]> {
        return this.httpClient
            .get<Result<Item[]>>(environment.apiUrl + '/misc/department', {
                params: new HttpParams()
                    .append('parentDepartmentId', parentDepartmentId)
                    .append('respectHierarchy', `${respectHierarchy}`)
                    .append('scope', scope),
            })
            .pipe(map(result => result.extra));
    }

    public parentDepartment(
        id: string
    ): Observable<{ parent: Item; children: Item[] }> {
        return this.httpClient
            .get<
                Result<{
                    parent: Item;
                    children: Item[];
                }>
            >(environment.apiUrl + '/misc/parent-department', {
                params: new HttpParams().append('departmentId', id),
            })
            .pipe(map(result => result.extra));
    }

    public kpiTags({ keyword = '', pageSize = 1000 } = {}): Observable<Item[]> {
        return this.httpClient
            .get<Result<Item[]>>(environment.apiUrl + '/misc/kpi-tag', {
                params: new HttpParams()
                    .append('keyword', keyword)
                    .append('pageSize', `${pageSize}`),
            })
            .pipe(map(result => result.extra));
    }

    public kpiResultCategories({
        keyword = '',
        types = [],
        pageSize = 0,
    } = {}): Observable<Item[]> {
        let params = new HttpParams()
            .append('keyword', keyword)
            .append('pageSize', `${pageSize}`);

        types?.forEach(x => (params = params.append('types', x)));

        return this.httpClient
            .get<Result<Item[]>>(
                environment.apiUrl + '/misc/kpi-result-category',
                {
                    params,
                }
            )
            .pipe(map(result => result.extra));
    }

    public kpiResultSubcategories({
        keyword = '',
        categoryIds = [],
        pageSize = 0,
    } = {}): Observable<Item[]> {
        let params = new HttpParams()
            .append('keyword', keyword)
            .append('pageSize', `${pageSize}`);

        categoryIds?.forEach(x => (params = params.append('categoryIds', x)));

        return this.httpClient
            .get<Result<Item[]>>(
                environment.apiUrl + '/misc/kpi-result-subcategory',
                { params }
            )
            .pipe(map(result => result.extra));
    }

    public years(): Observable<number[]> {
        return this.httpClient
            .get<Result<number[]>>(`${environment.apiUrl}/misc/year`)
            .pipe(map(res => res.extra));
    }

    public kpiTypes(): Observable<KpiType[]> {
        return this.httpClient
            .get<Result<KpiType[]>>(`${environment.apiUrl}/misc/kpi-type`)
            .pipe(map(res => res.extra));
    }

    public operations({
        parentOperationId = '',
        respectHierarchy = false,
        excludedOperationIds = [],
    } = {}): Observable<Item[]> {
        let params = new HttpParams()
            .append('parentOperationId', parentOperationId)
            .append('respectHierarchy', `${respectHierarchy}`);

        if (excludedOperationIds) {
            excludedOperationIds.forEach(
                item => (params = params.append('excludedOperationIds', item))
            );
        }

        return this.httpClient
            .get<Result<Item[]>>(`${environment.apiUrl}/misc/operation`, {
                params,
            })
            .pipe(map(res => res.extra));
    }

    public operationProcedures({
        keyword = '',
        operationIds = [],
        pageSize = 20,
    } = {}): Observable<Item[]> {
        let params = new HttpParams()
            .append('keyword', keyword)
            .append('pageSize', `${pageSize}`);

        if (operationIds && operationIds.length > 0) {
            operationIds.forEach(
                item => (params = params.append('operationIds', item))
            );
        }

        return this.httpClient
            .get<Result<Item[]>>(
                `${environment.apiUrl}/misc/operation-procedure`,
                {
                    params,
                }
            )
            .pipe(map(res => res.extra));
    }

    public parentOperation(parentOperationId: string): Observable<{
        parent: Item;
        children: Item[];
    }> {
        return this.httpClient
            .get<
                Result<{
                    parent: Item;
                    children: Item[];
                }>
            >(environment.apiUrl + '/misc/parent-operation', {
                params: new HttpParams().append(
                    'operationId',
                    parentOperationId
                ),
            })
            .pipe(map(result => result.extra));
    }

    public capabilities({ keyword = '', pageSize = 20 } = {}): Observable<
        Item[]
    > {
        return this.httpClient
            .get<Result<Item[]>>(`${environment.apiUrl}/misc/capability`, {
                params: new HttpParams()
                    .append('keyword', keyword)
                    .append('pageSize', `${pageSize}`),
            })
            .pipe(map(result => result.extra));
    }

    public tournaments({
        keyword = '',
        ids = [],
        pageSize = 20,
    } = {}): Observable<Item[]> {
        let params = new HttpParams()
            .append('keyword', keyword)
            .append('pageSize', `${pageSize}`);

        ids?.forEach(x => (params = params.append('ids', x)));

        return this.httpClient
            .get<Result<Item[]>>(`${environment.apiUrl}/misc/tournament`, {
                params,
            })
            .pipe(map(result => result.extra));
    }

    public pillars({
        keyword = '',
        ids = [],
        tournamentIds = [],
        pageSize = 20,
    } = {}): Observable<Item[]> {
        let params = new HttpParams()
            .append('keyword', keyword)
            .append('pageSize', `${pageSize}`);

        ids?.forEach(x => (params = params.append('ids', x)));
        tournamentIds?.forEach(
            x => (params = params.append('tournamentIds', x))
        );

        return this.httpClient
            .get<Result<Item[]>>(`${environment.apiUrl}/misc/pillar`, {
                params,
            })
            .pipe(map(result => result.extra));
    }

    public standards(
        filters: {
            keyword?: string;
            ids?: string[];
            tournamentIds?: string[];
            pillarIds?: string[];
            pageSize?: number;
        } = {}
    ): Observable<Item[]> {
        let params = new HttpParams()
            .append('keyword', filters.keyword || '')
            .append('pageSize', `${filters.pageSize || 20}`);

        filters.ids?.forEach(x => (params = params.append('ids', x)));
        filters.tournamentIds?.forEach(
            x => (params = params.append('tournamentIds', x))
        );
        filters.pillarIds?.forEach(
            x => (params = params.append('pillarIds', x))
        );

        return this.httpClient
            .get<Result<Item[]>>(`${environment.apiUrl}/misc/standard`, {
                params,
            })
            .pipe(map(result => result.extra));
    }

    public principles(
        filters: {
            keyword?: string;
            ids?: string[];
            tournamentIds?: string[];
            pillarIds?: string[];
            standardIds?: string[];
            pageSize?: number;
        } = {}
    ): Observable<Item[]> {
        let params = new HttpParams()
            .append('keyword', filters.keyword || '')
            .append('pageSize', `${filters.pageSize || 20}`);

        filters.ids?.forEach(x => (params = params.append('ids', x)));
        filters.tournamentIds?.forEach(
            x => (params = params.append('tournamentIds', x))
        );
        filters.pillarIds?.forEach(
            x => (params = params.append('pillarIds', x))
        );

        filters.standardIds?.forEach(
            x => (params = params.append('standardIds', x))
        );

        return this.httpClient
            .get<Result<Item[]>>(`${environment.apiUrl}/misc/principle`, {
                params,
            })
            .pipe(map(result => result.extra));
    }

    public standardTasks(
        filters: {
            keyword?: string;
            ids?: string[];
            tournamentIds?: string[];
            pillarIds?: string[];
            standardIds?: string[];
            pageSize?: number;
        } = {}
    ): Observable<Item[]> {
        let params = new HttpParams()
            .append('keyword', filters.keyword || '')
            .append('pageSize', `${filters.pageSize || 20}`);

        filters.ids?.forEach(x => (params = params.append('ids', x)));
        filters.tournamentIds?.forEach(
            x => (params = params.append('tournamentIds', x))
        );
        filters.pillarIds?.forEach(
            x => (params = params.append('pillarIds', x))
        );

        filters.standardIds?.forEach(
            x => (params = params.append('standardIds', x))
        );

        return this.httpClient
            .get<Result<Item[]>>(`${environment.apiUrl}/misc/standard-task`, {
                params,
            })
            .pipe(map(result => result.extra));
    }

    public standardSubtasks(
        filters: {
            keyword?: string;
            ids?: string[];
            tournamentIds?: string[];
            pillarIds?: string[];
            standardIds?: string[];
            taskIds?: string[];
            pageSize?: number;
        } = {}
    ): Observable<Item[]> {
        let params = new HttpParams()
            .append('keyword', filters.keyword || '')
            .append('pageSize', `${filters.pageSize || 20}`);

        filters.ids?.forEach(x => (params = params.append('ids', x)));
        filters.tournamentIds?.forEach(
            x => (params = params.append('tournamentIds', x))
        );
        filters.pillarIds?.forEach(
            x => (params = params.append('pillarIds', x))
        );

        filters.standardIds?.forEach(
            x => (params = params.append('standardIds', x))
        );

        filters.taskIds?.forEach(x => (params = params.append('taskIds', x)));

        return this.httpClient
            .get<Result<Item[]>>(
                `${environment.apiUrl}/misc/standard-subtask`,
                { params }
            )
            .pipe(map(result => result.extra));
    }

    public getList(
        endPoint: MiscApiEndPoint,
        httpQueryParameters?: HttpQueryParameters,
        noCache?: boolean
    ): Observable<MiscItem[]> {
        const params = getHttpParameters(httpQueryParameters);
        const url = `${environment.apiUrl}/misc/${endPoint}`;

        if (noCache) {
            return this.httpClient
                .get<Result<MiscItem[]>>(url, { params })
                .pipe(
                    map(res => res.extra),
                    this.getMapper(endPoint)
                );
        }

        return this.cache.get(url, params).pipe(this.getMapper(endPoint));
    }

    public getItem(
        endPoint: MiscApiEndPoint,
        id: string,
        httpQueryParameters?: HttpQueryParameters,
        noCache?: boolean
    ): Observable<MiscItem | undefined> {
        return this.getList(endPoint, httpQueryParameters, noCache).pipe(
            map(x => x.find(y => y.id === id))
        );
    }

    public getItemName(
        endPoint: MiscApiEndPoint,
        id: string,
        httpQueryParameters?: HttpQueryParameters,
        noCache?: boolean
    ): Observable<string> {
        return this.getItem(endPoint, id, httpQueryParameters, noCache).pipe(
            map(x => x?.name ?? id)
        );
    }

    public setMiscItems(
        formState: MnmFormState,
        list: (
            | [MiscApiEndPoint, string]
            | [
                  MiscApiEndPoint,
                  string,
                  HttpQueryParameters | undefined,
                  boolean
              ]
        )[]
    ): void {
        list.forEach(item =>
            this.getList(item[0], item[2], item[3]).subscribe(
                items => (formState.get(item[1]).items = items)
            )
        );
    }

    private getMapper(
        endPoint: MiscApiEndPoint
    ): OperatorFunction<unknown[], MiscItem[]> {
        if (endPoint === 'kpi') return this.kpiMapper();
        if (endPoint === 'strategic-goal') return this.strategicGoalMapper();
        if (endPoint === 'benchmark') return this.benchmarkMapper();
        if (endPoint === 'risk-impact') return this.riskImpactMapper();
        if (endPoint === 'risk-probability')
            return this.riskProbabilityMapper();
        if (endPoint === 'government-strategic-goal')
            return this.governmentStrategicGoalMapper();
        return map(x => x as MiscItem[]);
    }

    private kpiMapper(): OperatorFunction<KpiMiscItem[], KpiMiscItem[]> {
        return switchMap(items => {
            return this.appSettingFetcherService.get$.pipe(
                take(1),
                map(({ appId }) =>
                    items.map(item => {
                        item.name = `(${appId}-${item.type.code}-${item.code}) ${item.name}`;
                        return item;
                    })
                )
            );
        });
    }

    private strategicGoalMapper(): OperatorFunction<
        StrategicGoalMiscItem[],
        StrategicGoalMiscItem[]
    > {
        const other = this.translateService.instant('translate_other');

        return map(items => {
            return items.map(item => {
                return {
                    ...item,
                    yearRange:
                        item.fromYear && item.toYear
                            ? `${item.fromYear}-${item.toYear}`
                            : other,
                };
            });
        });
    }
    private governmentStrategicGoalMapper(): OperatorFunction<
        GovernmentStrategicGoalMiscItem[],
        GovernmentStrategicGoalMiscItem[]
    > {
        const other = this.translateService.instant('translate_other');

        return map(items => {
            return items.map(item => {
                return {
                    ...item,
                    yearRange:
                        item.fromYear && item.toYear
                            ? `${item.fromYear}-${item.toYear}`
                            : other,
                };
            });
        });
    }

    private benchmarkMapper(): OperatorFunction<
        BenchmarkMiscItem[],
        MiscItem[]
    > {
        return map(items => {
            return items.map(item => {
                const entityName =
                    item.entityType === 'partner'
                        ? item.partnerName
                        : item.entityName;

                const entityType = this.translateService.instant(
                    `translate_${item.entityType}`
                );

                const date = new Date(item.visitDate);
                const entityDate = date.toLocaleString('en-GB', {
                    timeZone: 'UTC',
                });

                return {
                    id: item.id,
                    name: `${entityType} - ${entityName} - ${entityDate}`,
                };
            });
        });
    }

    private riskImpactMapper(): OperatorFunction<
        RiskImpactMiscItem[],
        MiscItem[]
    > {
        return map(items => {
            return items.map(item => {
                return { ...item, name: `${item.degree} - ${item.name}` };
            });
        });
    }

    private riskProbabilityMapper(): OperatorFunction<
        RiskProbabilityMiscItem[],
        MiscItem[]
    > {
        return map(items => {
            return items.map(item => {
                return { ...item, name: `${item.degree} - ${item.name}` };
            });
        });
    }
}
